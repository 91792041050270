import api from 'services/api';

export interface IPostAlterEmail {
  created_at: string;
  deleted_at: string;
  email: string;
  id: string;
  is_verified: boolean;
  status: string;
  type: string;
  updated_at: string;
  username: string;
}

export function postAlterEmailValidation(token: string) {
  return api.post<IPostAlterEmail>('/configs/alter-email', { token });
}
