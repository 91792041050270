import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { HiringDataResponse, getHiringData } from 'requests/hiring';
import HiringCreateAccount from './CreateAccount';
import HiringError from './Error';
import CreatedAccountSuccesHiring from './Success';

export type Component = 'create' | 'error' | 'loading' | 'success';

interface QueryParamsProps {
  hiring_id: string;
}

const HiringRegister: React.FC = () => {
  const [renderComponent, setRenderComponent] = useState<Component>('loading');
  const [hiringData, setHiringData] = useState<HiringDataResponse>(
    {} as HiringDataResponse,
  );
  const { hiring_id }: QueryParamsProps = useParams();

  const renderElement = (component: Component) => {
    const Element = {
      loading: <></>,
      create: (
        <HiringCreateAccount
          hiringData={hiringData}
          setRenderComponent={setRenderComponent}
          hiringId={hiring_id}
        />
      ),
      error: <HiringError />,
      success: <CreatedAccountSuccesHiring hiringId={hiringData.hiring_id} />,
    };

    return Element[component];
  };

  useEffect(() => {
    getHiringData(hiring_id)
      .then(data => {
        setHiringData(data);
        setRenderComponent('create');
      })
      .catch(() => {
        setRenderComponent('error');
      });
  }, [hiring_id]);

  return renderElement(renderComponent);
};

export default HiringRegister;
