import React from 'react';
import { Box, Paper, Typography, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

interface props {
  data: any;
  code: string;
}

const DontHaveAccount: React.FC<React.PropsWithChildren<props>> = ({
  data,
  code,
}) => {
  const history = useHistory();

  function handleCreateAccount() {
    window.localStorage.setItem(
      'VINCULATION_COLLABORATOR',
      `{"email": "${data.email}", "linking_code": "${code}"}`,
    );
    history.push(`https://incicle.com/register/personalInfo`, data);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          width: '320px',
          pt: 4,
          pb: 4,
          pr: 2,
          pl: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Typography
          variant="body1"
          sx={{ textAlign: 'center', fontSize: '13px', color: '#333' }}
        >
          A empresa <strong>{data.company.name}</strong> enviou para você um
          convite de vinculação profissional.
        </Typography>
        <Typography
          variant="body1"
          sx={{ textAlign: 'center', fontSize: '13px', color: '#333' }}
        >
          Identificamos que você ainda não possui uma conta na InCicle.
        </Typography>
        <Typography
          variant="body1"
          sx={{ textAlign: 'center', fontSize: '13px', color: '#333' }}
        >
          Crie uma conta com o e-mail <strong>{data.email}</strong> para
          continuar com a vinculação.
        </Typography>
        <Box
          sx={{
            pl: 4,
            pr: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            mt: 2,
          }}
        >
          <Button variant="outlined" size="large" onClick={handleCreateAccount}>
            Criar conta na InCicle
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default DontHaveAccount;
