import api from 'services/api';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { TokenData } from 'interfaces/TokenData';
import { decode } from 'utils/crypto';

export const useInterceptor = (
  logout: Function,
  refreshToken: Function,
): void => {
  api.interceptors.request.use(
    async config => {
      try {
        if (config?.url?.includes('refresh-token')) return config;

        let token = Cookies.get('authToken');
        let isTokenExpiredOrAboutToExpire = false;
        let decodedToken;

        if (token) {
          decodedToken = decode(token);

          const { exp }: TokenData = jwtDecode(decodedToken);

          const unixExpirationTimestamp = +new Date(exp * 1000);
          const unixCurrentTimestamp = +new Date();

          isTokenExpiredOrAboutToExpire =
            unixExpirationTimestamp - unixCurrentTimestamp <= 10;
        }

        if (!token || isTokenExpiredOrAboutToExpire) {
          token = await refreshToken();
        }
        if (!token) {
          token = await refreshToken();
        }

        return {
          ...config,
          headers: {
            ...config.headers,
            ...(decodedToken && { Authorization: `Bearer ${decodedToken}` }),
          },
        };
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);

        return config;
      }
    },
    error => {
      return Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    response => {
      return Promise.resolve(response);
    },
    error => {
      if (error.response?.status === 401) {
        logout();
      }

      return Promise.reject(error);
    },
  );
};
