import { links } from 'app/config/links';
import api from 'services/api';

export interface CreatePersonalAccountProps {
  name: string;
  born: string;
  phone: string;
  email: string;
  email_confirmation: string;
  password: string;
  password_confirmation: string;
  timezone: string;
  accepted: boolean;
}

export const createPersonalAccount = async (
  data: CreatePersonalAccountProps,
) => {
  const response = await api.post(`${links.api.core}/register/person`, data);
  return response.data;
};
