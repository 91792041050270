import api from 'services/api';
import { links } from 'app/config/links';

export async function getVinculationData(email: string, code: string) {
  const response = api.post(
    `${links.api.core}/engineering/collaborators/check-token`,
    {
      email,
      linking_code: code,
    },
  );

  return response;
}

export async function answerVinculation(
  code: string,
  email: string,
  accept: boolean,
) {
  const response = await api.post(`/engineering/collaborators/${code}/accept`, {
    accepted: accept,
    email,
  });

  return response;
}
