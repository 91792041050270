import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  SelectHTMLAttributes,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import { Container, Error } from './../Input/styles';

interface InputProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  icon?: React.ComponentType<React.PropsWithChildren<IconBaseProps>>;
  children: React.ReactNode;
}

const Select: React.FC<React.PropsWithChildren<InputProps>> = ({
  name,
  children,
  ...rest
}: InputProps) => {
  const inputRef = useRef<HTMLSelectElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [inputValue, setInputValue] = useState(
    ((rest?.value as string) || rest?.defaultValue) ?? '',
  );
  const { fieldName, defaultValue, error, registerField, clearError } =
    useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
    if (error) {
      clearError();
    }
  }, []); // eslint-disable-line

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
    if (error) {
      clearError();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const clearFieldError = (e: any) => {
    if (error) clearError();
    setInputValue(e.target.value);
  };

  return (
    <Container
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      fieldType="select"
    >
      <select
        onFocus={handleInputFocus}
        value={inputValue}
        defaultValue={defaultValue}
        onBlur={handleInputBlur}
        ref={inputRef}
        {...rest}
        onChange={clearFieldError}
      >
        {children}
      </select>

      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default Select;
