import React, { useState } from 'react';
import { AnimationContainer } from 'hocs/withMainLayout/styles';
import EmailIcon from 'assets/icons/IconEmailBlue.svg';
import Button from 'components/Button';
import { checkToken } from 'requests/verify-account';

interface ConfirmCorporateAccountProps {
  email: string;
  token: string;
  setRenderType: React.Dispatch<React.SetStateAction<string>>;
}

const ConfirmCorporateAccount: React.FC<ConfirmCorporateAccountProps> = ({
  email,
  token,
  setRenderType,
}) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await checkToken(email, token);
      setRenderType('success');
    } catch (err) {
      setRenderType('error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <AnimationContainer>
      <img
        src={EmailIcon}
        alt="Email icon"
        style={{ width: '120px', marginBottom: '15px' }}
      />
      <h2>Confirme a criação de conta</h2>
      <p style={{ marginTop: '15px' }}>
        <strong>Agora você só precisa confirmar sua conta</strong>
      </p>
      <p style={{ marginTop: '15px', textAlign: 'center' }}>
        Ao confirmar a criação de conta, você entende que está de acordo com
        nossos <a href="https://www.incicle.com">Termos de Uso</a> e{' '}
        <a href="https://www.incicle.com">Políticas de Privacidade</a>.
      </p>
      <Button
        onClick={handleConfirm}
        type="button"
        style={{ marginTop: '30px' }}
        loading={loading}
      >
        Confirmar e criar conta
      </Button>
    </AnimationContainer>
  );
};

export default ConfirmCorporateAccount;
