import { links } from 'app/config/links';
import api from 'services/api';

export const getAccountType = async (email: string) => {
  const response = await api.get(
    `${links.api.core}/check-email?email=${email}`,
  );
  const accountType = response.data.type;
  return accountType;
};

export const checkToken = async (email: string, token: string) => {
  const response = await api.post(`${links.api.core}/check-token`, {
    email,
    token,
  });

  return response.data;
};
