import React from 'react';
import { AnimationContainer } from 'hocs/withMainLayout/styles';
import EmailIcon from 'assets/icons/IconEmailBlue.svg';

const EmailConfirm: React.FC = () => {
  return (
    <AnimationContainer>
      <img
        src={EmailIcon}
        alt="Email icon"
        style={{ width: '120px', marginBottom: '15px' }}
      />
      <h2>Verifique seu e-mail</h2>
      <p style={{ marginTop: '15px' }}>
        <strong>Agora você só precisa confirmar sua conta</strong>
      </p>
      <p style={{ marginTop: '15px', textAlign: 'center' }}>
        Para isso, acesse sua caixa de e-mail e clique no link de confirmação
        que enviamos para você dentro de um período de 24 horas.
      </p>
      <a style={{ marginTop: '15px' }} href="https://www.incicle.com">
        Voltar ao site
      </a>
    </AnimationContainer>
  );
};

export default EmailConfirm;
