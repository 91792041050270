export const timezoneList = [
  {
    id: 1,
    timezone: 'Africa/Abidjan',
    abbreviation: 'GMT',
    offset: '+00:00',
  },
  {
    id: 2,
    timezone: 'Africa/Accra',
    abbreviation: 'GMT',
    offset: '+00:00',
  },
  {
    id: 3,
    timezone: 'Africa/Algiers',
    abbreviation: 'CET',
    offset: '+01:00',
  },
  {
    id: 4,
    timezone: 'Africa/Bissau',
    abbreviation: 'GMT',
    offset: '+00:00',
  },
  {
    id: 5,
    timezone: 'Africa/Cairo',
    abbreviation: 'EET',
    offset: '+02:00',
  },
  {
    id: 6,
    timezone: 'Africa/Casablanca',
    abbreviation: '+01',
    offset: '+01:00',
  },
  {
    id: 7,
    timezone: 'Africa/Ceuta',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 8,
    timezone: 'Africa/El_Aaiun',
    abbreviation: '+01',
    offset: '+01:00',
  },
  {
    id: 9,
    timezone: 'Africa/Johannesburg',
    abbreviation: 'SAST',
    offset: '+02:00',
  },
  {
    id: 10,
    timezone: 'Africa/Juba',
    abbreviation: 'CAT',
    offset: '+02:00',
  },
  {
    id: 11,
    timezone: 'Africa/Khartoum',
    abbreviation: 'CAT',
    offset: '+02:00',
  },
  {
    id: 12,
    timezone: 'Africa/Lagos',
    abbreviation: 'WAT',
    offset: '+01:00',
  },
  {
    id: 13,
    timezone: 'Africa/Maputo',
    abbreviation: 'CAT',
    offset: '+02:00',
  },
  {
    id: 14,
    timezone: 'Africa/Monrovia',
    abbreviation: 'GMT',
    offset: '+00:00',
  },
  {
    id: 15,
    timezone: 'Africa/Nairobi',
    abbreviation: 'EAT',
    offset: '+03:00',
  },
  {
    id: 16,
    timezone: 'Africa/Ndjamena',
    abbreviation: 'WAT',
    offset: '+01:00',
  },
  {
    id: 17,
    timezone: 'Africa/Sao_Tome',
    abbreviation: 'GMT',
    offset: '+00:00',
  },
  {
    id: 18,
    timezone: 'Africa/Tripoli',
    abbreviation: 'EET',
    offset: '+02:00',
  },
  {
    id: 19,
    timezone: 'Africa/Tunis',
    abbreviation: 'CET',
    offset: '+01:00',
  },
  {
    id: 20,
    timezone: 'Africa/Windhoek',
    abbreviation: 'CAT',
    offset: '+02:00',
  },
  {
    id: 21,
    timezone: 'America/Adak',
    abbreviation: 'HDT',
    offset: '-09:00',
  },
  {
    id: 22,
    timezone: 'America/Anchorage',
    abbreviation: 'AKDT',
    offset: '-08:00',
  },
  {
    id: 23,
    timezone: 'America/Araguaina',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 24,
    timezone: 'America/Argentina/Buenos_Aires',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 25,
    timezone: 'America/Argentina/Catamarca',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 26,
    timezone: 'America/Argentina/Cordoba',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 27,
    timezone: 'America/Argentina/Jujuy',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 28,
    timezone: 'America/Argentina/La_Rioja',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 29,
    timezone: 'America/Argentina/Mendoza',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 30,
    timezone: 'America/Argentina/Rio_Gallegos',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 31,
    timezone: 'America/Argentina/Salta',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 32,
    timezone: 'America/Argentina/San_Juan',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 33,
    timezone: 'America/Argentina/San_Luis',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 34,
    timezone: 'America/Argentina/Tucuman',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 35,
    timezone: 'America/Argentina/Ushuaia',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 36,
    timezone: 'America/Asuncion',
    abbreviation: '-04',
    offset: '-04:00',
  },
  {
    id: 37,
    timezone: 'America/Atikokan',
    abbreviation: 'EST',
    offset: '-05:00',
  },
  {
    id: 38,
    timezone: 'America/Bahia',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 39,
    timezone: 'America/Bahia_Banderas',
    abbreviation: 'CDT',
    offset: '-05:00',
  },
  {
    id: 40,
    timezone: 'America/Barbados',
    abbreviation: 'AST',
    offset: '-04:00',
  },
  {
    id: 41,
    timezone: 'America/Belem',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 42,
    timezone: 'America/Belize',
    abbreviation: 'CST',
    offset: '-06:00',
  },
  {
    id: 43,
    timezone: 'America/Blanc-Sablon',
    abbreviation: 'AST',
    offset: '-04:00',
  },
  {
    id: 44,
    timezone: 'America/Boa_Vista',
    abbreviation: '-04',
    offset: '-04:00',
  },
  {
    id: 45,
    timezone: 'America/Bogota',
    abbreviation: '-05',
    offset: '-05:00',
  },
  {
    id: 46,
    timezone: 'America/Boise',
    abbreviation: 'MDT',
    offset: '-06:00',
  },
  {
    id: 47,
    timezone: 'America/Cambridge_Bay',
    abbreviation: 'MDT',
    offset: '-06:00',
  },
  {
    id: 48,
    timezone: 'America/Campo_Grande',
    abbreviation: '-04',
    offset: '-04:00',
  },
  {
    id: 49,
    timezone: 'America/Cancun',
    abbreviation: 'EST',
    offset: '-05:00',
  },
  {
    id: 50,
    timezone: 'America/Caracas',
    abbreviation: '-04',
    offset: '-04:00',
  },
  {
    id: 51,
    timezone: 'America/Cayenne',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 52,
    timezone: 'America/Chicago',
    abbreviation: 'CDT',
    offset: '-05:00',
  },
  {
    id: 53,
    timezone: 'America/Chihuahua',
    abbreviation: 'MDT',
    offset: '-06:00',
  },
  {
    id: 54,
    timezone: 'America/Costa_Rica',
    abbreviation: 'CST',
    offset: '-06:00',
  },
  {
    id: 55,
    timezone: 'America/Creston',
    abbreviation: 'MST',
    offset: '-07:00',
  },
  {
    id: 56,
    timezone: 'America/Cuiaba',
    abbreviation: '-04',
    offset: '-04:00',
  },
  {
    id: 57,
    timezone: 'America/Curacao',
    abbreviation: 'AST',
    offset: '-04:00',
  },
  {
    id: 58,
    timezone: 'America/Danmarkshavn',
    abbreviation: 'GMT',
    offset: '+00:00',
  },
  {
    id: 59,
    timezone: 'America/Dawson',
    abbreviation: 'MST',
    offset: '-07:00',
  },
  {
    id: 60,
    timezone: 'America/Dawson_Creek',
    abbreviation: 'MST',
    offset: '-07:00',
  },
  {
    id: 61,
    timezone: 'America/Denver',
    abbreviation: 'MDT',
    offset: '-06:00',
  },
  {
    id: 62,
    timezone: 'America/Detroit',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 63,
    timezone: 'America/Edmonton',
    abbreviation: 'MDT',
    offset: '-06:00',
  },
  {
    id: 64,
    timezone: 'America/Eirunepe',
    abbreviation: '-05',
    offset: '-05:00',
  },
  {
    id: 65,
    timezone: 'America/El_Salvador',
    abbreviation: 'CST',
    offset: '-06:00',
  },
  {
    id: 67,
    timezone: 'America/Fortaleza',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 66,
    timezone: 'America/Fort_Nelson',
    abbreviation: 'MST',
    offset: '-07:00',
  },
  {
    id: 68,
    timezone: 'America/Glace_Bay',
    abbreviation: 'ADT',
    offset: '-03:00',
  },
  {
    id: 69,
    timezone: 'America/Goose_Bay',
    abbreviation: 'ADT',
    offset: '-03:00',
  },
  {
    id: 70,
    timezone: 'America/Grand_Turk',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 71,
    timezone: 'America/Guatemala',
    abbreviation: 'CST',
    offset: '-06:00',
  },
  {
    id: 72,
    timezone: 'America/Guayaquil',
    abbreviation: '-05',
    offset: '-05:00',
  },
  {
    id: 73,
    timezone: 'America/Guyana',
    abbreviation: '-04',
    offset: '-04:00',
  },
  {
    id: 74,
    timezone: 'America/Halifax',
    abbreviation: 'ADT',
    offset: '-03:00',
  },
  {
    id: 75,
    timezone: 'America/Havana',
    abbreviation: 'CDT',
    offset: '-04:00',
  },
  {
    id: 76,
    timezone: 'America/Hermosillo',
    abbreviation: 'MST',
    offset: '-07:00',
  },
  {
    id: 77,
    timezone: 'America/Indiana/Indianapolis',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 78,
    timezone: 'America/Indiana/Knox',
    abbreviation: 'CDT',
    offset: '-05:00',
  },
  {
    id: 79,
    timezone: 'America/Indiana/Marengo',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 80,
    timezone: 'America/Indiana/Petersburg',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 81,
    timezone: 'America/Indiana/Tell_City',
    abbreviation: 'CDT',
    offset: '-05:00',
  },
  {
    id: 82,
    timezone: 'America/Indiana/Vevay',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 83,
    timezone: 'America/Indiana/Vincennes',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 84,
    timezone: 'America/Indiana/Winamac',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 85,
    timezone: 'America/Inuvik',
    abbreviation: 'MDT',
    offset: '-06:00',
  },
  {
    id: 86,
    timezone: 'America/Iqaluit',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 87,
    timezone: 'America/Jamaica',
    abbreviation: 'EST',
    offset: '-05:00',
  },
  {
    id: 88,
    timezone: 'America/Juneau',
    abbreviation: 'AKDT',
    offset: '-08:00',
  },
  {
    id: 89,
    timezone: 'America/Kentucky/Louisville',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 90,
    timezone: 'America/Kentucky/Monticello',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 91,
    timezone: 'America/La_Paz',
    abbreviation: '-04',
    offset: '-04:00',
  },
  {
    id: 92,
    timezone: 'America/Lima',
    abbreviation: '-05',
    offset: '-05:00',
  },
  {
    id: 93,
    timezone: 'America/Los_Angeles',
    abbreviation: 'PDT',
    offset: '-07:00',
  },
  {
    id: 94,
    timezone: 'America/Maceio',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 95,
    timezone: 'America/Managua',
    abbreviation: 'CST',
    offset: '-06:00',
  },
  {
    id: 96,
    timezone: 'America/Manaus',
    abbreviation: '-04',
    offset: '-04:00',
  },
  {
    id: 97,
    timezone: 'America/Martinique',
    abbreviation: 'AST',
    offset: '-04:00',
  },
  {
    id: 98,
    timezone: 'America/Matamoros',
    abbreviation: 'CDT',
    offset: '-05:00',
  },
  {
    id: 99,
    timezone: 'America/Mazatlan',
    abbreviation: 'MDT',
    offset: '-06:00',
  },
  {
    id: 100,
    timezone: 'America/Menominee',
    abbreviation: 'CDT',
    offset: '-05:00',
  },
  {
    id: 101,
    timezone: 'America/Merida',
    abbreviation: 'CDT',
    offset: '-05:00',
  },
  {
    id: 102,
    timezone: 'America/Metlakatla',
    abbreviation: 'AKDT',
    offset: '-08:00',
  },
  {
    id: 103,
    timezone: 'America/Mexico_City',
    abbreviation: 'CDT',
    offset: '-05:00',
  },
  {
    id: 104,
    timezone: 'America/Miquelon',
    abbreviation: '-02',
    offset: '-02:00',
  },
  {
    id: 105,
    timezone: 'America/Moncton',
    abbreviation: 'ADT',
    offset: '-03:00',
  },
  {
    id: 106,
    timezone: 'America/Monterrey',
    abbreviation: 'CDT',
    offset: '-05:00',
  },
  {
    id: 107,
    timezone: 'America/Montevideo',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 108,
    timezone: 'America/Nassau',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 109,
    timezone: 'America/New_York',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 110,
    timezone: 'America/Nipigon',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 111,
    timezone: 'America/Nome',
    abbreviation: 'AKDT',
    offset: '-08:00',
  },
  {
    id: 112,
    timezone: 'America/Noronha',
    abbreviation: '-02',
    offset: '-02:00',
  },
  {
    id: 113,
    timezone: 'America/North_Dakota/Beulah',
    abbreviation: 'CDT',
    offset: '-05:00',
  },
  {
    id: 114,
    timezone: 'America/North_Dakota/Center',
    abbreviation: 'CDT',
    offset: '-05:00',
  },
  {
    id: 115,
    timezone: 'America/North_Dakota/New_Salem',
    abbreviation: 'CDT',
    offset: '-05:00',
  },
  {
    id: 116,
    timezone: 'America/Nuuk',
    abbreviation: '-02',
    offset: '-02:00',
  },
  {
    id: 117,
    timezone: 'America/Ojinaga',
    abbreviation: 'MDT',
    offset: '-06:00',
  },
  {
    id: 118,
    timezone: 'America/Panama',
    abbreviation: 'EST',
    offset: '-05:00',
  },
  {
    id: 119,
    timezone: 'America/Pangnirtung',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 120,
    timezone: 'America/Paramaribo',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 121,
    timezone: 'America/Phoenix',
    abbreviation: 'MST',
    offset: '-07:00',
  },
  {
    id: 122,
    timezone: 'America/Port-au-Prince',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 123,
    timezone: 'America/Port_of_Spain',
    abbreviation: 'AST',
    offset: '-04:00',
  },
  {
    id: 124,
    timezone: 'America/Porto_Velho',
    abbreviation: '-04',
    offset: '-04:00',
  },
  {
    id: 125,
    timezone: 'America/Puerto_Rico',
    abbreviation: 'AST',
    offset: '-04:00',
  },
  {
    id: 126,
    timezone: 'America/Punta_Arenas',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 127,
    timezone: 'America/Rainy_River',
    abbreviation: 'CDT',
    offset: '-05:00',
  },
  {
    id: 128,
    timezone: 'America/Rankin_Inlet',
    abbreviation: 'CDT',
    offset: '-05:00',
  },
  {
    id: 129,
    timezone: 'America/Recife',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 130,
    timezone: 'America/Regina',
    abbreviation: 'CST',
    offset: '-06:00',
  },
  {
    id: 131,
    timezone: 'America/Resolute',
    abbreviation: 'CDT',
    offset: '-05:00',
  },
  {
    id: 132,
    timezone: 'America/Rio_Branco',
    abbreviation: '-05',
    offset: '-05:00',
  },
  {
    id: 133,
    timezone: 'America/Santarem',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 134,
    timezone: 'America/Santiago',
    abbreviation: '-04',
    offset: '-04:00',
  },
  {
    id: 135,
    timezone: 'America/Santo_Domingo',
    abbreviation: 'AST',
    offset: '-04:00',
  },
  {
    id: 136,
    timezone: 'America/Sao_Paulo',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 137,
    timezone: 'America/Scoresbysund',
    abbreviation: '+00',
    offset: '+00:00',
  },
  {
    id: 138,
    timezone: 'America/Sitka',
    abbreviation: 'AKDT',
    offset: '-08:00',
  },
  {
    id: 139,
    timezone: 'America/St_Johns',
    abbreviation: 'NDT',
    offset: '-02:30',
  },
  {
    id: 140,
    timezone: 'America/Swift_Current',
    abbreviation: 'CST',
    offset: '-06:00',
  },
  {
    id: 141,
    timezone: 'America/Tegucigalpa',
    abbreviation: 'CST',
    offset: '-06:00',
  },
  {
    id: 142,
    timezone: 'America/Thule',
    abbreviation: 'ADT',
    offset: '-03:00',
  },
  {
    id: 143,
    timezone: 'America/Thunder_Bay',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 144,
    timezone: 'America/Tijuana',
    abbreviation: 'PDT',
    offset: '-07:00',
  },
  {
    id: 145,
    timezone: 'America/Toronto',
    abbreviation: 'EDT',
    offset: '-04:00',
  },
  {
    id: 146,
    timezone: 'America/Vancouver',
    abbreviation: 'PDT',
    offset: '-07:00',
  },
  {
    id: 147,
    timezone: 'America/Whitehorse',
    abbreviation: 'MST',
    offset: '-07:00',
  },
  {
    id: 148,
    timezone: 'America/Winnipeg',
    abbreviation: 'CDT',
    offset: '-05:00',
  },
  {
    id: 149,
    timezone: 'America/Yakutat',
    abbreviation: 'AKDT',
    offset: '-08:00',
  },
  {
    id: 150,
    timezone: 'America/Yellowknife',
    abbreviation: 'MDT',
    offset: '-06:00',
  },
  {
    id: 151,
    timezone: 'Antarctica/Casey',
    abbreviation: '+11',
    offset: '+11:00',
  },
  {
    id: 152,
    timezone: 'Antarctica/Davis',
    abbreviation: '+07',
    offset: '+07:00',
  },
  {
    id: 153,
    timezone: 'Antarctica/DumontDUrville',
    abbreviation: '+10',
    offset: '+10:00',
  },
  {
    id: 154,
    timezone: 'Antarctica/Macquarie',
    abbreviation: 'AEST',
    offset: '+10:00',
  },
  {
    id: 155,
    timezone: 'Antarctica/Mawson',
    abbreviation: '+05',
    offset: '+05:00',
  },
  {
    id: 156,
    timezone: 'Antarctica/Palmer',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 157,
    timezone: 'Antarctica/Rothera',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 158,
    timezone: 'Antarctica/Syowa',
    abbreviation: '+03',
    offset: '+03:00',
  },
  {
    id: 159,
    timezone: 'Antarctica/Troll',
    abbreviation: '+02',
    offset: '+02:00',
  },
  {
    id: 160,
    timezone: 'Antarctica/Vostok',
    abbreviation: '+06',
    offset: '+06:00',
  },
  {
    id: 161,
    timezone: 'Asia/Almaty',
    abbreviation: '+06',
    offset: '+06:00',
  },
  {
    id: 162,
    timezone: 'Asia/Amman',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 163,
    timezone: 'Asia/Anadyr',
    abbreviation: '+12',
    offset: '+12:00',
  },
  {
    id: 164,
    timezone: 'Asia/Aqtau',
    abbreviation: '+05',
    offset: '+05:00',
  },
  {
    id: 165,
    timezone: 'Asia/Aqtobe',
    abbreviation: '+05',
    offset: '+05:00',
  },
  {
    id: 166,
    timezone: 'Asia/Ashgabat',
    abbreviation: '+05',
    offset: '+05:00',
  },
  {
    id: 167,
    timezone: 'Asia/Atyrau',
    abbreviation: '+05',
    offset: '+05:00',
  },
  {
    id: 168,
    timezone: 'Asia/Baghdad',
    abbreviation: '+03',
    offset: '+03:00',
  },
  {
    id: 169,
    timezone: 'Asia/Baku',
    abbreviation: '+04',
    offset: '+04:00',
  },
  {
    id: 170,
    timezone: 'Asia/Bangkok',
    abbreviation: '+07',
    offset: '+07:00',
  },
  {
    id: 171,
    timezone: 'Asia/Barnaul',
    abbreviation: '+07',
    offset: '+07:00',
  },
  {
    id: 172,
    timezone: 'Asia/Beirut',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 173,
    timezone: 'Asia/Bishkek',
    abbreviation: '+06',
    offset: '+06:00',
  },
  {
    id: 174,
    timezone: 'Asia/Brunei',
    abbreviation: '+08',
    offset: '+08:00',
  },
  {
    id: 175,
    timezone: 'Asia/Chita',
    abbreviation: '+09',
    offset: '+09:00',
  },
  {
    id: 176,
    timezone: 'Asia/Choibalsan',
    abbreviation: '+08',
    offset: '+08:00',
  },
  {
    id: 177,
    timezone: 'Asia/Colombo',
    abbreviation: '+0530',
    offset: '+05:30',
  },
  {
    id: 178,
    timezone: 'Asia/Damascus',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 179,
    timezone: 'Asia/Dhaka',
    abbreviation: '+06',
    offset: '+06:00',
  },
  {
    id: 180,
    timezone: 'Asia/Dili',
    abbreviation: '+09',
    offset: '+09:00',
  },
  {
    id: 181,
    timezone: 'Asia/Dubai',
    abbreviation: '+04',
    offset: '+04:00',
  },
  {
    id: 182,
    timezone: 'Asia/Dushanbe',
    abbreviation: '+05',
    offset: '+05:00',
  },
  {
    id: 183,
    timezone: 'Asia/Famagusta',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 184,
    timezone: 'Asia/Gaza',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 185,
    timezone: 'Asia/Hebron',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 186,
    timezone: 'Asia/Ho_Chi_Minh',
    abbreviation: '+07',
    offset: '+07:00',
  },
  {
    id: 187,
    timezone: 'Asia/Hong_Kong',
    abbreviation: 'HKT',
    offset: '+08:00',
  },
  {
    id: 188,
    timezone: 'Asia/Hovd',
    abbreviation: '+07',
    offset: '+07:00',
  },
  {
    id: 189,
    timezone: 'Asia/Irkutsk',
    abbreviation: '+08',
    offset: '+08:00',
  },
  {
    id: 190,
    timezone: 'Asia/Jakarta',
    abbreviation: 'WIB',
    offset: '+07:00',
  },
  {
    id: 191,
    timezone: 'Asia/Jayapura',
    abbreviation: 'WIT',
    offset: '+09:00',
  },
  {
    id: 192,
    timezone: 'Asia/Jerusalem',
    abbreviation: 'IDT',
    offset: '+03:00',
  },
  {
    id: 193,
    timezone: 'Asia/Kabul',
    abbreviation: '+0430',
    offset: '+04:30',
  },
  {
    id: 194,
    timezone: 'Asia/Kamchatka',
    abbreviation: '+12',
    offset: '+12:00',
  },
  {
    id: 195,
    timezone: 'Asia/Karachi',
    abbreviation: 'PKT',
    offset: '+05:00',
  },
  {
    id: 196,
    timezone: 'Asia/Kathmandu',
    abbreviation: '+0545',
    offset: '+05:45',
  },
  {
    id: 197,
    timezone: 'Asia/Khandyga',
    abbreviation: '+09',
    offset: '+09:00',
  },
  {
    id: 198,
    timezone: 'Asia/Kolkata',
    abbreviation: 'IST',
    offset: '+05:30',
  },
  {
    id: 199,
    timezone: 'Asia/Krasnoyarsk',
    abbreviation: '+07',
    offset: '+07:00',
  },
  {
    id: 200,
    timezone: 'Asia/Kuala_Lumpur',
    abbreviation: '+08',
    offset: '+08:00',
  },
  {
    id: 201,
    timezone: 'Asia/Kuching',
    abbreviation: '+08',
    offset: '+08:00',
  },
  {
    id: 202,
    timezone: 'Asia/Macau',
    abbreviation: 'CST',
    offset: '+08:00',
  },
  {
    id: 203,
    timezone: 'Asia/Magadan',
    abbreviation: '+11',
    offset: '+11:00',
  },
  {
    id: 204,
    timezone: 'Asia/Makassar',
    abbreviation: 'WITA',
    offset: '+08:00',
  },
  {
    id: 205,
    timezone: 'Asia/Manila',
    abbreviation: 'PST',
    offset: '+08:00',
  },
  {
    id: 206,
    timezone: 'Asia/Nicosia',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 207,
    timezone: 'Asia/Novokuznetsk',
    abbreviation: '+07',
    offset: '+07:00',
  },
  {
    id: 208,
    timezone: 'Asia/Novosibirsk',
    abbreviation: '+07',
    offset: '+07:00',
  },
  {
    id: 209,
    timezone: 'Asia/Omsk',
    abbreviation: '+06',
    offset: '+06:00',
  },
  {
    id: 210,
    timezone: 'Asia/Oral',
    abbreviation: '+05',
    offset: '+05:00',
  },
  {
    id: 211,
    timezone: 'Asia/Pontianak',
    abbreviation: 'WIB',
    offset: '+07:00',
  },
  {
    id: 212,
    timezone: 'Asia/Pyongyang',
    abbreviation: 'KST',
    offset: '+09:00',
  },
  {
    id: 213,
    timezone: 'Asia/Qatar',
    abbreviation: '+03',
    offset: '+03:00',
  },
  {
    id: 214,
    timezone: 'Asia/Qostanay',
    abbreviation: '+06',
    offset: '+06:00',
  },
  {
    id: 215,
    timezone: 'Asia/Qyzylorda',
    abbreviation: '+05',
    offset: '+05:00',
  },
  {
    id: 216,
    timezone: 'Asia/Riyadh',
    abbreviation: '+03',
    offset: '+03:00',
  },
  {
    id: 217,
    timezone: 'Asia/Sakhalin',
    abbreviation: '+11',
    offset: '+11:00',
  },
  {
    id: 218,
    timezone: 'Asia/Samarkand',
    abbreviation: '+05',
    offset: '+05:00',
  },
  {
    id: 219,
    timezone: 'Asia/Seoul',
    abbreviation: 'KST',
    offset: '+09:00',
  },
  {
    id: 220,
    timezone: 'Asia/Shanghai',
    abbreviation: 'CST',
    offset: '+08:00',
  },
  {
    id: 221,
    timezone: 'Asia/Singapore',
    abbreviation: '+08',
    offset: '+08:00',
  },
  {
    id: 222,
    timezone: 'Asia/Srednekolymsk',
    abbreviation: '+11',
    offset: '+11:00',
  },
  {
    id: 223,
    timezone: 'Asia/Taipei',
    abbreviation: 'CST',
    offset: '+08:00',
  },
  {
    id: 224,
    timezone: 'Asia/Tashkent',
    abbreviation: '+05',
    offset: '+05:00',
  },
  {
    id: 225,
    timezone: 'Asia/Tbilisi',
    abbreviation: '+04',
    offset: '+04:00',
  },
  {
    id: 226,
    timezone: 'Asia/Tehran',
    abbreviation: '+0430',
    offset: '+04:30',
  },
  {
    id: 227,
    timezone: 'Asia/Thimphu',
    abbreviation: '+06',
    offset: '+06:00',
  },
  {
    id: 228,
    timezone: 'Asia/Tokyo',
    abbreviation: 'JST',
    offset: '+09:00',
  },
  {
    id: 229,
    timezone: 'Asia/Tomsk',
    abbreviation: '+07',
    offset: '+07:00',
  },
  {
    id: 230,
    timezone: 'Asia/Ulaanbaatar',
    abbreviation: '+08',
    offset: '+08:00',
  },
  {
    id: 231,
    timezone: 'Asia/Urumqi',
    abbreviation: '+06',
    offset: '+06:00',
  },
  {
    id: 232,
    timezone: 'Asia/Ust-Nera',
    abbreviation: '+10',
    offset: '+10:00',
  },
  {
    id: 233,
    timezone: 'Asia/Vladivostok',
    abbreviation: '+10',
    offset: '+10:00',
  },
  {
    id: 234,
    timezone: 'Asia/Yakutsk',
    abbreviation: '+09',
    offset: '+09:00',
  },
  {
    id: 235,
    timezone: 'Asia/Yangon',
    abbreviation: '+0630',
    offset: '+06:30',
  },
  {
    id: 236,
    timezone: 'Asia/Yekaterinburg',
    abbreviation: '+05',
    offset: '+05:00',
  },
  {
    id: 237,
    timezone: 'Asia/Yerevan',
    abbreviation: '+04',
    offset: '+04:00',
  },
  {
    id: 238,
    timezone: 'Atlantic/Azores',
    abbreviation: '+00',
    offset: '+00:00',
  },
  {
    id: 239,
    timezone: 'Atlantic/Bermuda',
    abbreviation: 'ADT',
    offset: '-03:00',
  },
  {
    id: 240,
    timezone: 'Atlantic/Canary',
    abbreviation: 'WEST',
    offset: '+01:00',
  },
  {
    id: 241,
    timezone: 'Atlantic/Cape_Verde',
    abbreviation: '-01',
    offset: '-01:00',
  },
  {
    id: 242,
    timezone: 'Atlantic/Faroe',
    abbreviation: 'WEST',
    offset: '+01:00',
  },
  {
    id: 243,
    timezone: 'Atlantic/Madeira',
    abbreviation: 'WEST',
    offset: '+01:00',
  },
  {
    id: 244,
    timezone: 'Atlantic/Reykjavik',
    abbreviation: 'GMT',
    offset: '+00:00',
  },
  {
    id: 245,
    timezone: 'Atlantic/South_Georgia',
    abbreviation: '-02',
    offset: '-02:00',
  },
  {
    id: 246,
    timezone: 'Atlantic/Stanley',
    abbreviation: '-03',
    offset: '-03:00',
  },
  {
    id: 247,
    timezone: 'Australia/Adelaide',
    abbreviation: 'ACST',
    offset: '+09:30',
  },
  {
    id: 248,
    timezone: 'Australia/Brisbane',
    abbreviation: 'AEST',
    offset: '+10:00',
  },
  {
    id: 249,
    timezone: 'Australia/Broken_Hill',
    abbreviation: 'ACST',
    offset: '+09:30',
  },
  {
    id: 250,
    timezone: 'Australia/Darwin',
    abbreviation: 'ACST',
    offset: '+09:30',
  },
  {
    id: 251,
    timezone: 'Australia/Eucla',
    abbreviation: '+0845',
    offset: '+08:45',
  },
  {
    id: 252,
    timezone: 'Australia/Hobart',
    abbreviation: 'AEST',
    offset: '+10:00',
  },
  {
    id: 253,
    timezone: 'Australia/Lindeman',
    abbreviation: 'AEST',
    offset: '+10:00',
  },
  {
    id: 254,
    timezone: 'Australia/Lord_Howe',
    abbreviation: '+1030',
    offset: '+10:30',
  },
  {
    id: 255,
    timezone: 'Australia/Melbourne',
    abbreviation: 'AEST',
    offset: '+10:00',
  },
  {
    id: 256,
    timezone: 'Australia/Perth',
    abbreviation: 'AWST',
    offset: '+08:00',
  },
  {
    id: 257,
    timezone: 'Australia/Sydney',
    abbreviation: 'AEST',
    offset: '+10:00',
  },
  {
    id: 291,
    timezone: 'Europe/Amsterdam',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 292,
    timezone: 'Europe/Andorra',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 293,
    timezone: 'Europe/Astrakhan',
    abbreviation: '+04',
    offset: '+04:00',
  },
  {
    id: 294,
    timezone: 'Europe/Athens',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 295,
    timezone: 'Europe/Belgrade',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 296,
    timezone: 'Europe/Berlin',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 297,
    timezone: 'Europe/Brussels',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 298,
    timezone: 'Europe/Bucharest',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 299,
    timezone: 'Europe/Budapest',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 300,
    timezone: 'Europe/Chisinau',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 301,
    timezone: 'Europe/Copenhagen',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 302,
    timezone: 'Europe/Dublin',
    abbreviation: 'IST',
    offset: '+01:00',
  },
  {
    id: 303,
    timezone: 'Europe/Gibraltar',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 304,
    timezone: 'Europe/Helsinki',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 305,
    timezone: 'Europe/Istanbul',
    abbreviation: '+03',
    offset: '+03:00',
  },
  {
    id: 306,
    timezone: 'Europe/Kaliningrad',
    abbreviation: 'EET',
    offset: '+02:00',
  },
  {
    id: 307,
    timezone: 'Europe/Kiev',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 308,
    timezone: 'Europe/Kirov',
    abbreviation: '+03',
    offset: '+03:00',
  },
  {
    id: 309,
    timezone: 'Europe/Lisbon',
    abbreviation: 'WEST',
    offset: '+01:00',
  },
  {
    id: 310,
    timezone: 'Europe/London',
    abbreviation: 'BST',
    offset: '+01:00',
  },
  {
    id: 311,
    timezone: 'Europe/Luxembourg',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 312,
    timezone: 'Europe/Madrid',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 313,
    timezone: 'Europe/Malta',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 314,
    timezone: 'Europe/Minsk',
    abbreviation: '+03',
    offset: '+03:00',
  },
  {
    id: 315,
    timezone: 'Europe/Monaco',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 316,
    timezone: 'Europe/Moscow',
    abbreviation: 'MSK',
    offset: '+03:00',
  },
  {
    id: 317,
    timezone: 'Europe/Oslo',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 318,
    timezone: 'Europe/Paris',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 319,
    timezone: 'Europe/Prague',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 320,
    timezone: 'Europe/Riga',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 321,
    timezone: 'Europe/Rome',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 322,
    timezone: 'Europe/Samara',
    abbreviation: '+04',
    offset: '+04:00',
  },
  {
    id: 323,
    timezone: 'Europe/Saratov',
    abbreviation: '+04',
    offset: '+04:00',
  },
  {
    id: 324,
    timezone: 'Europe/Simferopol',
    abbreviation: 'MSK',
    offset: '+03:00',
  },
  {
    id: 325,
    timezone: 'Europe/Sofia',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 326,
    timezone: 'Europe/Stockholm',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 327,
    timezone: 'Europe/Tallinn',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 328,
    timezone: 'Europe/Tirane',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 329,
    timezone: 'Europe/Ulyanovsk',
    abbreviation: '+04',
    offset: '+04:00',
  },
  {
    id: 330,
    timezone: 'Europe/Uzhgorod',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 331,
    timezone: 'Europe/Vienna',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 332,
    timezone: 'Europe/Vilnius',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 333,
    timezone: 'Europe/Volgograd',
    abbreviation: '+03',
    offset: '+03:00',
  },
  {
    id: 334,
    timezone: 'Europe/Warsaw',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 335,
    timezone: 'Europe/Zaporozhye',
    abbreviation: 'EEST',
    offset: '+03:00',
  },
  {
    id: 336,
    timezone: 'Europe/Zurich',
    abbreviation: 'CEST',
    offset: '+02:00',
  },
  {
    id: 337,
    timezone: 'HST',
    abbreviation: 'HST',
    offset: '-10:00',
  },
  {
    id: 338,
    timezone: 'Indian/Chagos',
    abbreviation: '+06',
    offset: '+06:00',
  },
  {
    id: 339,
    timezone: 'Indian/Christmas',
    abbreviation: '+07',
    offset: '+07:00',
  },
  {
    id: 340,
    timezone: 'Indian/Cocos',
    abbreviation: '+0630',
    offset: '+06:30',
  },
  {
    id: 341,
    timezone: 'Indian/Kerguelen',
    abbreviation: '+05',
    offset: '+05:00',
  },
  {
    id: 342,
    timezone: 'Indian/Mahe',
    abbreviation: '+04',
    offset: '+04:00',
  },
  {
    id: 343,
    timezone: 'Indian/Maldives',
    abbreviation: '+05',
    offset: '+05:00',
  },
  {
    id: 344,
    timezone: 'Indian/Mauritius',
    abbreviation: '+04',
    offset: '+04:00',
  },
  {
    id: 345,
    timezone: 'Indian/Reunion',
    abbreviation: '+04',
    offset: '+04:00',
  },
  {
    id: 350,
    timezone: 'Pacific/Apia',
    abbreviation: '+13',
    offset: '+13:00',
  },
  {
    id: 351,
    timezone: 'Pacific/Auckland',
    abbreviation: 'NZST',
    offset: '+12:00',
  },
  {
    id: 352,
    timezone: 'Pacific/Bougainville',
    abbreviation: '+11',
    offset: '+11:00',
  },
  {
    id: 353,
    timezone: 'Pacific/Chatham',
    abbreviation: '+1245',
    offset: '+12:45',
  },
  {
    id: 354,
    timezone: 'Pacific/Chuuk',
    abbreviation: '+10',
    offset: '+10:00',
  },
  {
    id: 355,
    timezone: 'Pacific/Easter',
    abbreviation: '-06',
    offset: '-06:00',
  },
  {
    id: 356,
    timezone: 'Pacific/Efate',
    abbreviation: '+11',
    offset: '+11:00',
  },
  {
    id: 357,
    timezone: 'Pacific/Enderbury',
    abbreviation: '+13',
    offset: '+13:00',
  },
  {
    id: 358,
    timezone: 'Pacific/Fakaofo',
    abbreviation: '+13',
    offset: '+13:00',
  },
  {
    id: 359,
    timezone: 'Pacific/Fiji',
    abbreviation: '+12',
    offset: '+12:00',
  },
  {
    id: 360,
    timezone: 'Pacific/Funafuti',
    abbreviation: '+12',
    offset: '+12:00',
  },
  {
    id: 361,
    timezone: 'Pacific/Galapagos',
    abbreviation: '-06',
    offset: '-06:00',
  },
  {
    id: 362,
    timezone: 'Pacific/Gambier',
    abbreviation: '-09',
    offset: '-09:00',
  },
  {
    id: 363,
    timezone: 'Pacific/Guadalcanal',
    abbreviation: '+11',
    offset: '+11:00',
  },
  {
    id: 364,
    timezone: 'Pacific/Guam',
    abbreviation: 'ChST',
    offset: '+10:00',
  },
  {
    id: 365,
    timezone: 'Pacific/Honolulu',
    abbreviation: 'HST',
    offset: '-10:00',
  },
  {
    id: 366,
    timezone: 'Pacific/Kiritimati',
    abbreviation: '+14',
    offset: '+14:00',
  },
  {
    id: 367,
    timezone: 'Pacific/Kosrae',
    abbreviation: '+11',
    offset: '+11:00',
  },
  {
    id: 368,
    timezone: 'Pacific/Kwajalein',
    abbreviation: '+12',
    offset: '+12:00',
  },
  {
    id: 369,
    timezone: 'Pacific/Majuro',
    abbreviation: '+12',
    offset: '+12:00',
  },
  {
    id: 370,
    timezone: 'Pacific/Marquesas',
    abbreviation: '-0930',
    offset: '-09:30',
  },
  {
    id: 371,
    timezone: 'Pacific/Nauru',
    abbreviation: '+12',
    offset: '+12:00',
  },
  {
    id: 372,
    timezone: 'Pacific/Niue',
    abbreviation: '-11',
    offset: '-11:00',
  },
  {
    id: 373,
    timezone: 'Pacific/Norfolk',
    abbreviation: '+11',
    offset: '+11:00',
  },
  {
    id: 374,
    timezone: 'Pacific/Noumea',
    abbreviation: '+11',
    offset: '+11:00',
  },
  {
    id: 375,
    timezone: 'Pacific/Pago_Pago',
    abbreviation: 'SST',
    offset: '-11:00',
  },
  {
    id: 376,
    timezone: 'Pacific/Palau',
    abbreviation: '+09',
    offset: '+09:00',
  },
  {
    id: 377,
    timezone: 'Pacific/Pitcairn',
    abbreviation: '-08',
    offset: '-08:00',
  },
  {
    id: 378,
    timezone: 'Pacific/Pohnpei',
    abbreviation: '+11',
    offset: '+11:00',
  },
  {
    id: 379,
    timezone: 'Pacific/Port_Moresby',
    abbreviation: '+10',
    offset: '+10:00',
  },
  {
    id: 380,
    timezone: 'Pacific/Rarotonga',
    abbreviation: '-10',
    offset: '-10:00',
  },
  {
    id: 381,
    timezone: 'Pacific/Tahiti',
    abbreviation: '-10',
    offset: '-10:00',
  },
  {
    id: 382,
    timezone: 'Pacific/Tarawa',
    abbreviation: '+12',
    offset: '+12:00',
  },
  {
    id: 383,
    timezone: 'Pacific/Tongatapu',
    abbreviation: '+13',
    offset: '+13:00',
  },
  {
    id: 384,
    timezone: 'Pacific/Wake',
    abbreviation: '+12',
    offset: '+12:00',
  },
  {
    id: 385,
    timezone: 'Pacific/Wallis',
    abbreviation: '+12',
    offset: '+12:00',
  },
];
