import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

import {
  answerVinculation,
  getVinculationData,
} from 'hooks/getVinculationData';
import { useAuth } from 'hooks/auth';

import HasConfirmedVinculation from './HasConfirmed';
import HasRefusedVinculation from './HasRefusedVinculation';
import DifferentEmail from './DifferentEmail';
import IsNotLogged from './IsNotLogged';
import DontHaveAccount from './DontHaveAccount';
import IsLoggedForVinculation from './IsLogged';

function Loading() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <CircularProgress />
    </Box>
  );
}

const NewConfirmVinculationPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const [loading, setLoading] = useState(true);
  const [differentEmail, setDifferentEmail] = useState(false);
  const [expiredCode, setExpiredCode] = useState(false);
  const [data, setData] = useState({}) as any;
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [hasRefused, setHasRefused] = useState(false);
  const { user } = useAuth();
  const { code, email }: { code: string; email: string } = useParams();

  useEffect(() => {
    getVinculationData(email, code)
      .then((response: any) => {
        setData(response?.data);
        if (!(response?.data?.email === user.email)) setDifferentEmail(true);
      })
      .catch(() => {
        setExpiredCode(true);
      })
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line

  const handleAnswerVinculation = async (answer: boolean) => {
    setLoading(true);
    answerVinculation(code, email, answer)
      .then(() => {
        if (answer) {
          setHasConfirmed(true);
        } else {
          setHasRefused(true);
        }
      })
      .finally(() => setLoading(false));
  };

  if (loading) return <Loading />;
  if (data.person === null) return <DontHaveAccount data={data} code={code} />;
  if (!user) return <IsNotLogged email={email} code={code} data={data} />;
  if (differentEmail) return <DifferentEmail email={email} code={code} />;
  if (hasConfirmed) return <HasConfirmedVinculation />;
  if (hasRefused) return <HasRefusedVinculation />;

  return (
    <React.Fragment>
      {user && code && email && (
        <IsLoggedForVinculation
          expiredCode={expiredCode}
          data={data}
          handleAnswerVinculation={handleAnswerVinculation}
        />
      )}
    </React.Fragment>
  );
};

export default NewConfirmVinculationPage;
