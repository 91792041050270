import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import pt_BR from 'locale/pt_BR.json';
import en_US from 'locale/en_US.json';

const resources = {
  'pt-BR': {
    translation: {
      ...pt_BR,
    },
  },
  'en-US': {
    translation: {
      ...en_US,
    },
  },
};

const supportedLanguage = ['pt-BR', 'en-US'];
const detectedLanguage = navigator.language;

const defineLanguage = supportedLanguage.includes(detectedLanguage)
  ? detectedLanguage
  : 'en-US';

i18n.use(initReactI18next).init({
  resources,
  lng: defineLanguage,
});

export default i18n;
