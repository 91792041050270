import React from 'react';
import { Route as ReactDOMRoute } from 'react-router-dom';

import { RouteProps } from 'interfaces/RouteProps';

const RouteIn: React.FC<React.PropsWithChildren<RouteProps>> = ({
  component: Component,
  ...rest
}) => {
  return <ReactDOMRoute exact {...rest} render={() => <Component />} />;
};

export default RouteIn;
