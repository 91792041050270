// @ts-nocheck
import { links } from 'app/config/links';
import AnimatedConfirmIcon from 'components/Animations/AnimatedConfirmIcon';
import Button from 'components/Button';
import { AnimationContainer } from 'hocs/withMainLayout/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CreatedAccountSuccess: React.FC = () => {
  const { t } = useTranslation();

  const handleLogin = () => {
    window.location.href = `${links.web.core}/signin`;
  };

  return (
    <AnimationContainer>
      <AnimatedConfirmIcon />
      <h2>
        {t('register_page.success_confirm.your_account_has_been_created')}
      </h2>
      <p style={{ marginTop: '15px', textAlign: 'center' }}>
        {t('register_page.success_confirm.congratulations')}
      </p>

      <Button
        type="button"
        style={{ marginTop: '25px', width: 'fit-content' }}
        onClick={handleLogin}
      >
        {t('register_page.success_confirm.go_to_login')}
      </Button>
    </AnimationContainer>
  );
};

export default CreatedAccountSuccess;
