import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { links } from 'app/config/links';

interface props {
  data: any;
  handleAnswerVinculation: (answer: boolean) => Promise<void>;
  expiredCode: boolean;
}

const sharedInfo = ['Nome completo', 'Data de nascimento', 'Telefone'];

const IsLoggedForVinculation: React.FC<React.PropsWithChildren<props>> = ({
  data,
  handleAnswerVinculation,
  expiredCode,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          width: '320px',
          pt: 4,
          pb: 4,
          pr: 2,
          pl: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {expiredCode ? (
          <>
            <Typography
              variant="h3"
              sx={{ textAlign: 'center', fontSize: '15px', color: '#333' }}
            >
              Parece que o código de vinculação é inválido ou expirou.
            </Typography>
            <Typography
              variant="h3"
              sx={{ textAlign: 'center', fontSize: '15px', color: '#333' }}
            >
              Por favor, solicite à sua empresa o envio de um novo código.
            </Typography>
            <Button
              variant="outlined"
              size="large"
              component="a"
              href={links.web.social}
            >
              Voltar para o sistema
            </Button>
          </>
        ) : (
          <React.Fragment>
            <Typography
              variant="h3"
              sx={{
                textAlign: 'center',
                fontSize: '15px',
                fontWeight: 'bold',
                color: '#333',
              }}
            >
              Olá, {data.person.name}!
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: 'center', fontSize: '13px', color: '#333' }}
            >
              A empresa <strong>{data.company.name}</strong> enviou para você um
              pedido de vinculação.
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: 'center', fontSize: '13px', color: '#333' }}
            >
              Ao confirmar o vínculo, você entende que algumas informações serão
              compartilhadas com a empresa, como:
            </Typography>
            <List dense={false}>
              {sharedInfo.map(item => (
                <ListItem sx={{ pt: 0, pb: 0 }}>
                  <ListItemText
                    sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}
                    primary={item}
                  />
                </ListItem>
              ))}
            </List>
            <Box
              sx={{
                pl: 4,
                pr: 4,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                mt: 2,
              }}
            >
              <Button
                variant="contained"
                size="large"
                onClick={() => handleAnswerVinculation(true)}
              >
                Confirmar vínculo
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={() => handleAnswerVinculation(false)}
              >
                Recusar
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Paper>
    </Box>
  );
};

export default IsLoggedForVinculation;
