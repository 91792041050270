import { links } from 'app/config/links';
import api from 'services/api';
import { CreatePersonalAccountProps } from './create-person';

export const checkVinculationToken = async (
  email: string,
  linking_code: string,
) => {
  const response = await api.post(
    `${links.api.core}/engineering/collaborators/check-token`,
    {
      email,
      linking_code,
    },
  );

  return response.data;
};

export interface CreatePersonalVinculationAccountProps
  extends CreatePersonalAccountProps {
  linking_code: string;
}

export const createPersonalVinculationAccount = async (
  data: CreatePersonalVinculationAccountProps,
) => {
  const response = await api.post(`${links.api.core}/register/person`, data);
  return response.data;
};
