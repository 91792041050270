import React, { useRef, useState } from 'react';
import { FiMail, FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useToast } from 'hooks/toast';

import logoImg from 'assets/logo_0.svg';

import Input from 'components/Input';
import Button from 'components/Button';

import getValidationErrors from 'utils/getValidationErrors';

import 'react-circular-progressbar/dist/styles.css';

import { sendVerificationCode } from 'hooks/forgot';
import { withMainLayout } from 'hocs/withMainLayout';

interface ForgotFormData {
  email: string;
}

const ForgotPassword: React.FC<React.PropsWithChildren<unknown>> = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSubmit = async (data: ForgotFormData) => {
    setSubmitting(true);

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .required('E-mail obrigatório')
          .email('Digite um e-mail válido'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await sendVerificationCode({ email: data.email });

      if (!response.success) {
        throw new Error(`Error: ${response.status}`);
      }

      setSubmitting(false);

      addToast({
        type: 'success',
        title: 'Email enviado.',
        description:
          'As etapas para recuperação de senha foram enviadas para o email.',
      });
      setTimeout(() => {
        history.replace('/');
      }, 1500);
    } catch (err) {
      setSubmitting(false);

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      }
      addToast({
        type: 'error',
        title: 'Falha ao realizar a solicitação',
        description:
          'Ocorreu um erro ao solicitar sua nova senha. Por favor, verifique o email fornecido.',
      });
    }
  };

  return (
    <>
      {/* @ts-ignore */}
      <Form ref={formRef} onSubmit={handleSubmit}>
        <img src={logoImg} alt="avatar" />

        <h2>Recuperar senha</h2>

        <Input name="email" icon={FiMail} placeholder="E-mail" />

        <Button type="submit" disabled={submitting}>
          Solicitar
        </Button>
      </Form>
      <Link to="/">
        <FiArrowLeft />
        Voltar para o login
      </Link>
    </>
  );
};

export default withMainLayout(ForgotPassword);
