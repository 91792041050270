import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonAdvanced: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  ...rest
}) => (
  <Container type="button" {...rest}>
    {children}
  </Container>
);

export default ButtonAdvanced;
