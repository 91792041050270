import React, { useEffect, useState } from 'react';
import { withMainLayout } from 'hocs/withMainLayout';
import { checkToken, getAccountType } from 'requests/verify-account';
import { useParams } from 'react-router-dom';
import ErrorComponent from './Error';
import CreatedAccountSuccess from './CreatedSuccess';
import ConfirmCorporateAccount from './ConfirmCorporateAccount';

const ConfirmAccount: React.FC = () => {
  const { email, token }: { email: string; token: string } = useParams();
  const [loading, setLoading] = useState(true);
  const [renderType, setRenderType] = useState('company');

  const RenderElement = (selectRender: string) => {
    const Element = {
      company: (
        <ConfirmCorporateAccount
          email={email}
          token={token}
          setRenderType={setRenderType}
        />
      ),
      error: <ErrorComponent />,
      success: <CreatedAccountSuccess />,
    };

    return Element[selectRender];
  };

  useEffect(() => {
    const verifyAccount = async () => {
      try {
        const accountType = await getAccountType(email);

        if (accountType === 'PERSON') {
          await checkToken(email, token);
          setRenderType('success');
        }

        if (accountType === 'COMPANY') {
          setRenderType('company');
        }
      } catch (err) {
        setRenderType('error');
      } finally {
        setLoading(false);
      }
    };

    verifyAccount();
  }, [email, token]);

  if (loading) return <></>;

  return RenderElement(renderType);
};

export default withMainLayout(ConfirmAccount);
