import Home from 'pages/Home';
import SignIn from 'pages/SignIn';
import ForgotPassword from 'pages/ForgotPassword';
import ForgotVerification from 'pages/ForgotPassword/Verification';
import UnverifiedUser from 'pages/UnverifiedUser';
import NewConfirmVinculationPage from 'pages/Invitation/Collaborator/NewConfirmVinculation';
import RegisterPerson from 'pages/Register/Person';
import ConfirmAccount from 'pages/Register/ConfirmAccount';
import VinculationPage from 'pages/Vinculation';
import HiringRegister from 'pages/Register/HiringRegister';
import EventRegister from 'pages/Register/Event';
import EmailChange from 'pages/EmailChange';

export const unauthenticatedRoutes = [
  {
    path: '/',
    Component: SignIn,
  },
  {
    path: '/signin',
    Component: SignIn,
  },
  {
    path: '/register/person',
    Component: RegisterPerson,
  },
  {
    path: '/register/confirm/:email/:token',
    Component: ConfirmAccount,
  },
  {
    path: '/register/personal/hiring/:hiring_id',
    Component: HiringRegister,
  },
  {
    path: '/register/personal/event/:event_id/invite/:invite_code',
    Component: EventRegister,
  },
  {
    path: '/vinculation/:linking_code/:email',
    Component: VinculationPage,
  },
  {
    path: '/accept-vinculation/:code/:email',
    Component: NewConfirmVinculationPage,
  },
  {
    path: '/forgot',
    Component: ForgotPassword,
  },
  {
    path: '/forgot/verification/:token',
    Component: ForgotVerification,
  },
  {
    path: '/unverified-user/:userEmail',
    Component: UnverifiedUser,
  },
  {
    path: '/settings/new-email',
    Component: EmailChange,
  },
];
