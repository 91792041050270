import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { checkVinculationToken } from 'requests/vinculation-collaborator';
import VinculationCreateAccount from './CreateAccount';
import InvalidVinculation from './Invalid';

export interface VinculationDataProps {
  email: string;
  linking_code: string;
  company_name: string;
  company_avatar: string;
}

const VinculationPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState('');
  const [vinculationData, setVinculationData] =
    useState<VinculationDataProps | null>(null);

  const { email, linking_code }: { email: string; linking_code: string } =
    useParams();
  const history = useHistory();

  const renderElement = (renderType: string) => {
    const Element = {
      invalid: <InvalidVinculation />,
      create: <VinculationCreateAccount vinculation_data={vinculationData} />,
    };

    return Element[renderType];
  };

  useEffect(() => {
    const checkToken = async () => {
      try {
        const tokenChecked = await checkVinculationToken(email, linking_code);
        const hasAccount = Boolean(tokenChecked.person);

        if (hasAccount) {
          history.push(`/accept-vinculation/${linking_code}/${email}`);
          return;
        }

        setVinculationData({
          email,
          linking_code,
          company_name: tokenChecked.company.name,
          company_avatar: tokenChecked.company.avatar,
        });

        setRender('create');
      } catch (err) {
        setRender('invalid');
      } finally {
        setLoading(false);
      }
    };

    checkToken();
  }, [email, linking_code, history]);

  if (loading) return <></>;

  return renderElement(render);
};

export default VinculationPage;
