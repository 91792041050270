import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { links } from 'app/config/links';
import { useQuery } from 'hooks/useQuery';
import { postAlterEmailValidation } from 'services/requests/config';
import Loader from 'components/Loader';

import AnimatedConfirmIcon from 'components/Animations/AnimatedConfirmIcon';
import AnimatedErrorIcon from 'components/Animations/AnimatedErrorIcon';

enum PageStatus {
  'EMPTY_PARAMS' = 0,
  'REQUEST_ERROR' = 1,
  'REQUEST_SUCCESS' = 2,
  'LOADING' = 4,
}

enum RequestError {
  'NOT_FOUND' = 404,
  'SERVER_ERROR' = 500,
  'OTHER' = 999,
}

const RenderError: React.FC<{ title: string; message: string }> = ({
  title,
  message,
}) => (
  <Stack textAlign="center" gap={2}>
    <Typography variant="h3" color="error">
      {title}
    </Typography>
    <Typography variant="body1">{message}</Typography>
  </Stack>
);

const AnchorButton: React.FC<React.PropsWithChildren<{ url: string }>> = ({
  url,
  children,
}) => (
  <Button
    variant="outlined"
    size="large"
    component="a"
    href={url}
    sx={{ minHeight: 'max-content' }}
  >
    {children}
  </Button>
);

const EmailChange: React.FC<React.PropsWithChildren<unknown>> = () => {
  const query = useQuery();

  const [email, setEmail] = useState<string | null>(null);
  const [pageStatus, setPageStatus] = useState(PageStatus.LOADING);
  const [requestError, setRequestError] = useState(RequestError.OTHER);

  const { token } = useMemo(() => {
    return {
      token: query.get('token'),
    };
  }, [query]);

  function handleUpdateEmail(tokenParam: string) {
    postAlterEmailValidation(tokenParam)
      .then(({ data }) => {
        setEmail(data.email);
        setPageStatus(PageStatus.REQUEST_SUCCESS);
      })
      .catch(err => {
        setPageStatus(PageStatus.REQUEST_ERROR);

        switch (err.response.status) {
          case RequestError.NOT_FOUND:
            setRequestError(RequestError.NOT_FOUND);
            break;
          case RequestError.SERVER_ERROR:
            setRequestError(RequestError.SERVER_ERROR);
            break;
          default:
            setRequestError(RequestError.OTHER);
            break;
        }
      });
  }

  useEffect(() => {
    if (!token) {
      setPageStatus(PageStatus.EMPTY_PARAMS);
      return;
    }

    handleUpdateEmail(token);
  }, [token]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          width: '80%',
          maxWidth: '360px',
          pt: 4,
          pb: 4,
          pr: 2,
          pl: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {pageStatus === PageStatus.REQUEST_SUCCESS && (
          <React.Fragment>
            <AnimatedConfirmIcon />

            <Typography
              variant="h2"
              sx={{ textAlign: 'center', color: '#333' }}
            >
              E-mail alterado com sucesso!
            </Typography>
            {email ? (
              <Typography variant="body1" textAlign="center">
                Seu novo e-mail de acesso é{' '}
                <Typography
                  component="span"
                  color="primary"
                  sx={{ overflowWrap: 'break-word' }}
                >
                  {email}.
                </Typography>{' '}
                Prossiga para a tela inicial.
              </Typography>
            ) : (
              <Typography textAlign="center">
                Prossiga para a tela inicial.
              </Typography>
            )}
            <Box
              sx={{
                pl: 4,
                pr: 4,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                mt: 2,
              }}
            >
              <AnchorButton url={links.web.social}>
                Ir para a InCicle
              </AnchorButton>
            </Box>
          </React.Fragment>
        )}

        {pageStatus === PageStatus.REQUEST_ERROR && (
          <React.Fragment>
            {requestError === RequestError.NOT_FOUND && (
              <React.Fragment>
                <AnimatedErrorIcon size={100} />
                <RenderError
                  title="Acesso expirado!"
                  message="Tente gerar um novo link de alteração de e-mail."
                />
              </React.Fragment>
            )}
            {requestError === RequestError.SERVER_ERROR && (
              <React.Fragment>
                <AnimatedErrorIcon size={100} />
                <RenderError
                  title="Servidor não responde."
                  message="Houve um erro em nossos servidores. O acesso está temporariamente inoperante."
                />
              </React.Fragment>
            )}
            {requestError === RequestError.OTHER && (
              <React.Fragment>
                <AnimatedErrorIcon size={100} />
                <RenderError
                  title="Não foi possível atualizar o email."
                  message="Ocorreu um erro inesperado ao atualizar o email de acesso."
                />
                <AnchorButton
                  url={`/settings/new-email?email=${email}&token=${token}`}
                >
                  Tente novamente
                </AnchorButton>
                <Typography
                  component="small"
                  fontStyle="italic"
                  fontSize="1.1rem"
                >
                  Se o erro persistir, gere um novo link de alteração de email.
                </Typography>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {pageStatus === PageStatus.LOADING && (
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            sx={{ marginLeft: 2 }}
          >
            <Loader size="20px" />
            <Typography component="span">Carregando...</Typography>
          </Stack>
        )}

        {pageStatus === PageStatus.EMPTY_PARAMS && (
          <React.Fragment>
            <RenderError
              title="Dados inválidos!"
              message="Token de acesso encontra-se ausente."
            />
          </React.Fragment>
        )}
      </Paper>
    </Box>
  );
};

export default EmailChange;
