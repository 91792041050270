import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EventDataResponse, getEventData } from 'requests/event';
import EventCreateAccount from './CreateAccount';
import EventError from './Error';
import Success from './Success';

export type Component = 'create' | 'error' | 'loading' | 'success';

interface QueryParamsProps {
  event_id: string;
  invite_code: string;
}

const EventRegister: React.FC = () => {
  const [renderComponent, setRenderComponent] = useState<Component>('loading');
  const [eventData, setEventData] = useState<EventDataResponse>(
    {} as EventDataResponse,
  );
  const { event_id, invite_code }: QueryParamsProps = useParams();

  const renderElement = (component: Component) => {
    const Element = {
      loading: <></>,
      create: (
        <EventCreateAccount
          eventData={eventData}
          setRenderComponent={setRenderComponent}
        />
      ),
      error: <EventError />,
      success: <Success eventId={event_id} />,
    };

    return Element[component];
  };

  useEffect(() => {
    getEventData(event_id, invite_code)
      .then(data => {
        setEventData(data);
        setRenderComponent('create');
      })
      .catch(() => {
        setRenderComponent('error');
      });
  }, [event_id, invite_code]);

  return renderElement(renderComponent);
};

export default EventRegister;
