import React from 'react';
import { AnimatedIconContainer } from './styles';

const AnimatedConfirmIcon: React.FC = () => (
  <AnimatedIconContainer>
    <div className="success-checkmark">
      <div className="check-icon">
        <span className="icon-line line-tip"></span>
        <span className="icon-line line-long"></span>
        <div className="icon-circle"></div>
        <div className="icon-fix"></div>
      </div>
    </div>
  </AnimatedIconContainer>
);

export default AnimatedConfirmIcon;
