import React, { useState, useCallback, useMemo } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import ButtonAdvanced from 'components/ButtonAdvanced';
import Button from 'components/Button';
import { withMainLayout } from 'hocs/withMainLayout';
import logoImg from 'assets/logo_0.svg';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { InformationContainer } from './styles';

const UnverifiedUser: React.FC<React.PropsWithChildren<unknown>> = () => {
  const timeToSendEmail = 0;
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();

  const { userEmail }: { userEmail: string } = useParams();

  const handleNewConfirmationEmail = useCallback(async () => {
    setLoading(true);

    try {
      await api.post('/account/resend-activation', {
        email: userEmail,
      });

      addToast({
        type: 'success',
        title: 'E-mail enviado',
        description: 'O e-mail de confirmação foi enviado com sucesso.',
      });

      history.push('/');
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Falha ao realizar a solicitação',
        description:
          'Ocorreu um erro ao solicitar um novo e-mail. Por favor verifique o email fornecido.',
      });
    } finally {
      setLoading(false);
    }
  }, [userEmail, addToast, history]);

  const isAbleToSendEmail = useMemo(
    () => timeToSendEmail === 0,
    [timeToSendEmail],
  );

  const timeString = useMemo(
    () =>
      `${Math.floor(timeToSendEmail / 60)}:${String(timeToSendEmail % 60)
        .substring(0, 2)
        .padStart(2, '0')}`,
    [timeToSendEmail],
  );

  return (
    <>
      <InformationContainer>
        <img src={logoImg} alt="logo" />
        <h2>Sua conta de usuário ainda não foi verificada!</h2>
        <p>
          Verifique sua caixa de entrada e caixa de spam para realizar a
          validação e começar a usar o sistema.
        </p>
        {!isAbleToSendEmail && (
          <p>
            <b>Você poderá requisitar um novo e-mail em:</b>
          </p>
        )}
        <Button
          disabled={!isAbleToSendEmail}
          onClick={handleNewConfirmationEmail}
          loading={loading}
        >
          {timeToSendEmail === 0
            ? 'Reenviar e-mail de confirmação'
            : timeString}
        </Button>
        <Link to="/">
          <ButtonAdvanced>Voltar</ButtonAdvanced>
        </Link>
      </InformationContainer>
    </>
  );
};

export default withMainLayout(UnverifiedUser);
