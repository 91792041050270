import React, { ComponentType, FunctionComponent } from 'react';

import { Container, Content, Background } from './styles';

export type ConditionalWrapperProps = {};

export const withMainLayout =
  <P extends ConditionalWrapperProps>(
    WrappedComponent: ComponentType<React.PropsWithChildren<P>>,
  ): FunctionComponent<React.PropsWithChildren<P>> =>
  (props: P) => {
    const isLoginPage = window.location.pathname === '/signin';

    return (
      <Container>
        <Background flexShrink={isLoginPage ? 0.7 : 1.0} />
        <Content>
          <WrappedComponent {...props} />
        </Content>
      </Container>
    );
  };
