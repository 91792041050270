import React from 'react';
import { links } from 'app/config/links';
import { Box, Paper, Typography, Button } from '@mui/material';

interface props {
  email: string;
  code: string;
  data: any;
}

const IsNotLogged: React.FC<React.PropsWithChildren<props>> = ({
  email,
  code,
  data,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          width: '320px',
          pt: 4,
          pb: 4,
          pr: 2,
          pl: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            textAlign: 'center',
            fontSize: '13px',
            color: '#333',
            fontWeight: 'bold',
          }}
        >
          Olá, {data.person.name}
        </Typography>
        <Typography
          variant="body1"
          sx={{ textAlign: 'center', fontSize: '13px', color: '#333' }}
        >
          Você recebeu um convite na InCicle. Faça login para visualizar.
        </Typography>
        <Box
          sx={{
            pl: 4,
            pr: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            mt: 2,
          }}
        >
          <Button
            variant="outlined"
            size="large"
            component="a"
            href={`/signin?redirect_to=${links.web.core}/accept-vinculation/${code}/${email}`}
          >
            Fazer login na InCicle
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default IsNotLogged;
