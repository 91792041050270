import React from 'react';
import { AnimationContainer } from 'hocs/withMainLayout/styles';
import { withMainLayout } from 'hocs/withMainLayout';

const HiringError: React.FC = () => {
  return (
    <AnimationContainer>
      <h2>Erro!</h2>
      <p style={{ marginTop: '15px', textAlign: 'center' }}>
        Parece que o link de contratação enviado para você é inválido, expirou
        ou foi removido. Por favor, contacte a empresa responsável.
      </p>
      <a style={{ marginTop: '15px' }} href="https://www.incicle.com">
        Voltar ao site
      </a>
    </AnimationContainer>
  );
};

export default withMainLayout(HiringError);
