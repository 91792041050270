import api from '../services/api';
import ApiConfig from '../config/api';

interface SendCodeDTO {
  email: string;
}

interface CheckCodeDTO {
  code: string;
}

interface ChangePasswordDTO {
  token: string;
  password: string;
  password_confirmation: string;
}

async function sendVerificationCode(
  sendCodeDTO: SendCodeDTO,
): Promise<{ success: boolean; status?: number; isNewEmailSent?: boolean }> {
  try {
    const response = await api.post(
      `${ApiConfig.urlV1}/account/forgot-password`,
      {
        email: sendCodeDTO.email,
      },
    );

    if (response.status === 200 && !response.data.errors) {
      return { success: true };
    }
    if (response.status === 204 && !response.data.errors) {
      return { success: true };
    }

    return { success: false };
  } catch (error) {
    const { response }: any = error;

    return {
      success: false,
      status: response.status as any,
      isNewEmailSent: response?.data?.isNewEmailSent as any,
    };
  }
}

async function checkVerificationCode(
  checkCodeDTO: CheckCodeDTO,
): Promise<boolean> {
  try {
    const response = await api.post(
      `${ApiConfig.urlV1}/account/forgot/verify`,
      {
        code: checkCodeDTO.code,
      },
    );

    if (response.status === 200 && !response.data.errors) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
}

async function changePasswordWithCode(
  changePasswordDTO: ChangePasswordDTO,
): Promise<boolean> {
  try {
    const response = await api.post(
      `${ApiConfig.urlV1}/account/reset-password`,
      {
        token: changePasswordDTO.token,
        password: changePasswordDTO.password,
        password_confirmation: changePasswordDTO.password_confirmation,
      },
    );

    if (response.status === 200 && !response.data.errors) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
}

export { sendVerificationCode, checkVerificationCode, changePasswordWithCode };
