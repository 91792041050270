/* eslint-disable @typescript-eslint/ban-types */
// @ts-nocheck
import React, {
  useRef,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { removeAuthCookies, useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { useQuery } from 'hooks/useQuery';

import getValidationErrors from 'utils/getValidationErrors';

import logoImg from 'assets/lock_login.png';

import Input from 'components/Input';
import Button from 'components/Button';

import { SignInCredentials } from 'interfaces/SignInCredentials';
import { withMainLayout } from 'hocs/withMainLayout';
import Cookies from 'js-cookie';
import { AnimationContainer } from 'hocs/withMainLayout/styles';
import { useTranslation } from 'react-i18next';

const domainName = (() => {
  const domain = window.location.hostname.split('.').slice(-2).join('.');
  return domain;
})();

const socialNetworkUrl = process.env.REACT_APP_WEB_URL_SOCIAL_NETWORK || '';

const SigIn: React.FC<React.PropsWithChildren<unknown>> = () => {
  const formRef = useRef<FormHandles>(null);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const query = useQuery();

  const currentLanguage = i18n.language;

  const { signIn, user } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const routeToRedirect = useMemo(() => {
    const url = query.get('redirect_to');

    if (url) {
      const rule = /[h][t]{2}[p]s?[:][/]{2}/;
      const protocol = /^https:\/\//.test(socialNetworkUrl) ? 'https' : 'http';
      const urlToRedirect = `${protocol}://${url.replace(rule, '')}`;
      return urlToRedirect;
    }

    return socialNetworkUrl;
  }, []); // eslint-disable-line

  const handleSubmit = useCallback(
    async (data: SignInCredentials) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });

        if (data.email === 'inciclecompany@incicle.com') {
          return (window.location.href = `https://admin.${domainName}`);
        }

        return routeToRedirect.slice(0, 4) === 'http'
          ? window.location.replace(routeToRedirect)
          : (window.location.href = routeToRedirect);
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        if (err?.response?.status === 409) {
          return history.push(
            `/unverified-user/${formRef.current?.getFieldValue('email')}`,
          );
        }

        if (err?.response?.status === 500) {
          return addToast({
            type: 'error',
            title: t('login_page.errors.authentication_title'),
            description: t('login_page.errors.authentication_description'),
          });
        }

        if (err?.response?.status === 401) {
          return addToast({
            type: 'error',
            title: t('login_page.errors.authentication_title'),
            description: t('login_page.errors.invalid_credentials'),
          });
        }

        addToast({
          type: 'error',
          title: t('login_page.errors.authentication_title'),
          description: t('login_page.errors.invalid_credentials'),
        });
      } finally {
        setLoading(false);
      }
    },
    [signIn, addToast], // eslint-disable-line
  );

  useEffect(() => {
    const newSession = query.get('session');

    if (newSession && newSession === 'new') {
      removeAuthCookies();
    }

    if (user && !newSession) {
      let expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 320);

      const encodedStoredToken = window.localStorage.getItem('authToken');
      const encodedStoredExpiresIn = window.localStorage.getItem('expiresIn');
      const encodedStoredUser = window.localStorage.getItem('user');

      if (encodedStoredToken && encodedStoredExpiresIn && encodedStoredUser) {
        Cookies.set('authToken', encodedStoredToken, {
          domain: domainName,
          secure: true,
          expires: expirationDate,
        });

        Cookies.set('expiresIn', encodedStoredExpiresIn, {
          domain: domainName,
          secure: true,
          expires: expirationDate,
        });

        Cookies.set('user', encodedStoredUser, {
          domain: domainName,
          secure: true,
          expires: expirationDate,
        });
      }

      window.location.href = routeToRedirect;
    }
  }, []); // eslint-disable-line

  return (
    <AnimationContainer>
      {/* @ts-ignore */}
      <Form ref={formRef} onSubmit={handleSubmit}>
        <img src={logoImg} alt="logo" style={{ width: 100, height: 140 }} />

        <h2>{t('login_page.system_access')}</h2>

        <Input
          name="email"
          type="email"
          icon={FiMail}
          placeholder={t('login_page.email')}
        />

        <Input
          name="password"
          icon={FiLock}
          type="password"
          placeholder={t('login_page.password')}
        />

        <Link to="/forgot">{t('login_page.forgot_password')}</Link>

        <Button loading={loading} disabled={loading} type="submit">
          {t('login_page.enter')}
        </Button>
      </Form>

      <a
        href={
          currentLanguage === 'pt-BR'
            ? 'https://www.incicle.com/cadastro'
            : 'https://www.incicle.com/register'
        }
      >
        <FiLogIn />
        {t('login_page.create_account')}
      </a>
    </AnimationContainer>
  );
};

export default withMainLayout(SigIn);
