import { links } from 'app/config/links';
import api from 'services/api';
import { CreatePersonalAccountProps } from './create-person';

export interface EventDataResponse {
  id: number;
  email: string;
  event_id: string;
}

export interface CreatePersonalEventAccountProps
  extends CreatePersonalAccountProps {
  invite_code: number;
}

export const getEventData = async (eventId: string, inviteCode: string) => {
  const response = await api.get(
    `${links.api.schedule}/events/${eventId}/invites/${inviteCode}/check`,
  );

  return response.data;
};

export const createPersonalEventAccount = async (
  data: CreatePersonalEventAccountProps,
) => {
  const response = await api.post(`${links.api.core}/register/person`, data);
  return response.data as EventDataResponse;
};
