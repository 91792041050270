import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { withMainLayout } from 'hocs/withMainLayout';

import logoImg from 'assets/logo_0.svg';
import { AnimationContainer } from 'hocs/withMainLayout/styles';

const Page404: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <AnimationContainer>
      <img src={logoImg} alt="avatar" />
      <h1>Ops!</h1>
      <h3>Essa página não pode ser encontrada.</h3>

      <Link to="/" style={{ marginTop: '15px' }}>
        <FiArrowLeft />
        Voltar para a página principal
      </Link>
    </AnimationContainer>
  );
};

export default withMainLayout(Page404);
