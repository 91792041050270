import { links } from 'app/config/links';
import AnimatedConfirmIcon from 'components/Animations/AnimatedConfirmIcon';
import Button from 'components/Button';
import { withMainLayout } from 'hocs/withMainLayout';
import { AnimationContainer } from 'hocs/withMainLayout/styles';
import React from 'react';

interface CreatedAccountSuccessHiringProps {
  hiringId: string;
}

const CreatedAccountSuccesHiring: React.FC<
  CreatedAccountSuccessHiringProps
> = ({ hiringId }) => {
  const handleLogin = () => {
    window.location.href = `${links.web.core}/signin?redirect_to=${links.web.personal_department}/user/job_details/${hiringId}`;
  };

  return (
    <AnimationContainer>
      <AnimatedConfirmIcon />
      <h2>Sua conta foi criada!</h2>
      <p style={{ marginTop: '15px', textAlign: 'center' }}>
        Faça login com o e-mail e senha cadastrados para acessar a contratação
        enviada para você!
      </p>

      <Button
        type="button"
        style={{ marginTop: '25px', width: 'fit-content' }}
        onClick={handleLogin}
      >
        Ir para o login
      </Button>
    </AnimationContainer>
  );
};

export default withMainLayout(CreatedAccountSuccesHiring);
