import React from 'react';
import { AnimationContainer } from 'hocs/withMainLayout/styles';
import { withMainLayout } from 'hocs/withMainLayout';

const InvalidVinculation: React.FC = () => {
  return (
    <AnimationContainer>
      <h2>Erro!</h2>
      <p style={{ marginTop: '15px', textAlign: 'center' }}>
        Parece que o pedido de vinculação não existe ou expirou. Por favor,
        entre em contato com a empresa para enviar uma nova solicitação de
        vinculação profissional.
      </p>
      <a style={{ marginTop: '15px' }} href="https://www.incicle.com">
        Voltar ao site
      </a>
    </AnimationContainer>
  );
};

export default withMainLayout(InvalidVinculation);
