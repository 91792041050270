import styled from 'styled-components';

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 40px 0;
  width: 340px;
  text-align: center;

  h2 {
    margin-bottom: 24px;
    color: #212121;
  }

  p {
    text-align: center;
    color: #212121;
    margin: 10px 0;
  }

  img {
    height: 180px;
    width: 180px;
    margin-bottom: 20px;
  }
`;
