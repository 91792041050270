import { links } from 'app/config/links';
import api from 'services/api';
import { CreatePersonalAccountProps } from './create-person';

export interface HiringDataResponse {
  id: string;
  hiring_id: string;
  email: string;
  name: string;
  phone_number: string;
  born_date: string;
  company: {
    id: string;
    name: string;
    avatar: string;
  };
}

export interface CreatePersonalHiringAccountProps
  extends CreatePersonalAccountProps {
  hiring_code: string;
}

export const getHiringData = async (caditateId: string) => {
  const response = await api.get(
    `${links.api.personal_department}/candidates/${caditateId}/check`,
  );

  return response.data as HiringDataResponse;
};

export const createPersonalHiringAccount = async (
  data: CreatePersonalHiringAccountProps,
) => {
  const response = await api.post(`${links.api.core}/register/person`, data);
  return response.data;
};
