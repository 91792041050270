import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { removeAuthCookies } from 'hooks/auth';

import ReactGA4 from 'react-ga4';

import { useQuery } from 'hooks/useQuery';
import Routes from './routes';
import AppProvider from './hooks';
import GlobalStyle from './styles/alternative';

const isProduction = JSON.parse(process.env.REACT_APP_PRODUCTION!);
const trackingGA = 'G-7Q7CBPZCNS';

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [loading, setLoading] = useState(true);
  const query = useQuery();
  useEffect(() => {
    const signout = query.get('signout');
    if (signout === 'true') {
      removeAuthCookies();
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    ReactGA4.initialize(trackingGA, { testMode: Boolean(!isProduction) });
    ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  if (loading) return <></>;

  return (
    <Router>
      <AppProvider>
        <Routes />
      </AppProvider>

      <GlobalStyle />
    </Router>
  );
};

export default App;
