import React, { useEffect } from 'react';
import { Box, Paper, Typography, Button } from '@mui/material';
import { links } from 'app/config/links';

const HasConfirmedVinculation: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  useEffect(() => {
    localStorage.removeItem('VINCULATION_COLLABORATOR');
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          width: '320px',
          pt: 4,
          pb: 4,
          pr: 2,
          pl: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Typography
          variant="body1"
          sx={{ textAlign: 'center', fontSize: '13px', color: '#333' }}
        >
          A sua vinculação profissional foi confirmada
        </Typography>
        <Box
          sx={{
            pl: 4,
            pr: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            mt: 2,
          }}
        >
          <Button
            variant="outlined"
            size="large"
            component="a"
            href={links.web.social}
          >
            Voltar para o sistema
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default HasConfirmedVinculation;
