/* eslint-disable @typescript-eslint/ban-types */
import React, { useRef, useCallback, useState } from 'react';
import { FiMail, FiLock, FiUser, FiCalendar, FiPhone } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';
import Input from 'components/Input';
import Button from 'components/Button';
import { withMainLayout } from 'hocs/withMainLayout';
import {
  AnimationContainer,
  GridCol,
  GridContainer,
  GridRow,
} from 'hocs/withMainLayout/styles';
import Select from 'components/CustomSelect';
import { timezoneList } from 'config/timezoneList';
import { CreatePersonalVinculationAccountProps } from 'requests/vinculation-collaborator';
import {
  CreatePersonalEventAccountProps,
  EventDataResponse,
  createPersonalEventAccount,
} from 'requests/event';
import { Component } from '.';

const phoneMask = value => {
  if (!value) return '';
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
};

const handlePhone = e => {
  let input = e.target;
  input.value = phoneMask(input.value);
};

const dateMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1');
};

const handleDate = e => {
  let input = e.target;
  input.value = dateMask(input.value);
};

const convertDate = value => {
  let date = value.split('/');
  return `${date[2]}-${date[1]}-${date[0]}`;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Campo obrigatório')
    .min(4, 'Mínimo de 4 caracteres')
    .max(255, 'Máximo de 255 caracteres'),
  phone: yup.string().required('Campo obrigatório').max(15),
  born: yup.string().required('Campo obrigatório'),
  password: yup
    .string()
    .required('Campo obrigatório')
    .min(8, 'Mínimo de 8 caracteres'),
  password_confirmation: yup
    .string()
    .required('Campo obrigatório')
    .oneOf([yup.ref('password')], 'As senhas devem ser idênticas')
    .min(8, 'Mínimo de 8 caracteres'),
  timezone: yup.string().required('O fuso horário é obrigatório.'),
});

interface EventCreateAccountProps {
  eventData: EventDataResponse;
  setRenderComponent: React.Dispatch<React.SetStateAction<Component>>;
}

const EventCreateAccount: React.FC<EventCreateAccountProps> = ({
  eventData,
  setRenderComponent,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: CreatePersonalVinculationAccountProps) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, {
          abortEarly: false,
        });

        const newData: CreatePersonalEventAccountProps = {
          ...data,
          born: convertDate(data.born),
          email_confirmation: data.email,
          accepted: true,
          invite_code: eventData.id,
        };

        await createPersonalEventAccount(newData);
        setRenderComponent('success');
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [], // eslint-disable-line
  );

  return (
    <>
      <AnimationContainer width={572}>
        {/* @ts-ignore */}
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2 style={{ marginBottom: '5px' }}>
            Você foi convidado para um evento
          </h2>
          <p style={{ marginBottom: '15px' }}>
            Finalize seu cadastro para acessar os detalhes do evento. É
            gratuito!
          </p>

          <GridContainer>
            <GridRow>
              <GridCol xs={6} lg={6} sm={6}>
                <Input
                  icon={FiUser}
                  name="name"
                  placeholder="Informe seu nome"
                  required
                  maxLength={255}
                  minLength={4}
                />
              </GridCol>
              <GridCol xs={6} lg={6} sm={6}>
                <Input
                  icon={FiCalendar}
                  name="born"
                  type="text"
                  placeholder="Data de nascimento"
                  required
                  onKeyUp={e => handleDate(e)}
                  maxLength={10}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol xs={12} lg={12} sm={12}>
                <Input
                  name="phone"
                  type="tel"
                  icon={FiPhone}
                  placeholder="Telefone"
                  required
                  onKeyUp={e => handlePhone(e)}
                  maxLength={15}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol xs={12} lg={12} sm={12}>
                <Input
                  name="email"
                  type="email"
                  icon={FiMail}
                  placeholder="E-mail"
                  disabled
                  required
                  value={eventData.email}
                  title="Você não pode editar o e-mail. Deve ser o mesmo informado pela empresa."
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol xs={6} lg={6} sm={6}>
                <Input
                  name="password"
                  icon={FiLock}
                  type="password"
                  placeholder="Senha"
                  min={8}
                />
              </GridCol>
              <GridCol xs={6} lg={6} sm={6}>
                <Input
                  name="password_confirmation"
                  icon={FiLock}
                  type="password"
                  placeholder="Repita a senha"
                  min={8}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol xs={12} lg={12} sm={12}>
                <Select name="timezone" defaultValue="America/Sao_Paulo">
                  {timezoneList.map(timezone => (
                    <option key={timezone.timezone} value={timezone.timezone}>
                      {timezone.timezone} ({timezone.offset})
                    </option>
                  ))}
                </Select>
              </GridCol>
            </GridRow>
          </GridContainer>

          <Button loading={loading} disabled={loading} type="submit">
            Criar conta
          </Button>
        </Form>
      </AnimationContainer>
    </>
  );
};

export default withMainLayout(EventCreateAccount);
