import styled from 'styled-components';

export const Container = styled.button`
  margin-top: 8px;
  border-radius: 50px;
  border: 1px solid #e8e8e8;
  background: #fff;
  width: 100%;
  text-decoration: none;
  max-height: 39px;
  color: black;

  font-size: 1.2rem;
  font-weight: 500;
  padding: 11px 22px;
  transition: background-color 0.2s;

  cursor: pointer;

  &:hover {
    background: #00568b;
    border: 1px solid #e8e8e8;
    color: #fff;
  }
`;
