import React from 'react';
import { Switch } from 'react-router-dom';
import { useAuth } from 'hooks/auth';
import Page404 from 'pages/Error/Page404';
import { useInterceptor } from 'hooks/useInterceptor';
import RouteIn from './Route';
import { unauthenticatedRoutes } from './routes';

const Routes: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { user, signOut, refreshToken } = useAuth();

  useInterceptor(signOut, refreshToken);

  const createUnauthenticatedRoutes = () => {
    return unauthenticatedRoutes.map(({ Component, path }) => (
      <RouteIn
        key={path}
        component={Component}
        path={path}
        isAuthenticated={!!user}
        exact
      />
    ));
  };

  return (
    <Switch>
      {createUnauthenticatedRoutes()}
      <RouteIn path="*" component={Page404} />
    </Switch>
  );
};

export default Routes;
