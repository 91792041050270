import React from 'react';
import { AnimationContainer } from 'hocs/withMainLayout/styles';
import { links } from 'app/config/links';
import { withMainLayout } from 'hocs/withMainLayout';

const ErrorComponent: React.FC = () => {
  return (
    <AnimationContainer>
      <h2>Erro!</h2>
      <p style={{ marginTop: '15px' }}>
        <strong>Não foi possível confirmar a criação da conta</strong>
      </p>
      <p style={{ marginTop: '15px', textAlign: 'center' }}>
        É possível que o e-mail esteja incorreto ou que o link de ativação tenha
        sido expirado. Você pode tentar fazer login novamente para receber um
        novo link de ativação.
      </p>
      <a style={{ marginTop: '15px' }} href={links.web.core}>
        Voltar ao login
      </a>
    </AnimationContainer>
  );
};

export default withMainLayout(ErrorComponent);
