import React from 'react';
import { Box, Paper, Typography, Button } from '@mui/material';
import { links } from 'app/config/links';

interface props {
  email: string;
  code: string;
}

const DifferentEmail: React.FC<React.PropsWithChildren<props>> = ({
  email,
  code,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          width: '320px',
          pt: 4,
          pb: 4,
          pr: 2,
          pl: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Typography
          variant="body1"
          sx={{ textAlign: 'center', fontSize: '13px', color: '#333' }}
        >
          Parece que a conta que você está usando é diferente da conta para qual
          o convite de vinculação foi enviado.
        </Typography>
        <Typography
          variant="body1"
          sx={{ textAlign: 'center', fontSize: '13px', color: '#333' }}
        >
          Por favor, faça login com o e-mail <strong>{email}</strong>
        </Typography>
        <Box
          sx={{
            pl: 4,
            pr: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            mt: 2,
          }}
        >
          <Button
            variant="outlined"
            size="large"
            component="a"
            style={{ width: 'max-content', whiteSpace: 'nowrap' }}
            href={`/signin?session=new&redirect_to=${links.web.core}/accept-vinculation/${code}/${email}`}
          >
            Fazer login com outra conta
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default DifferentEmail;
