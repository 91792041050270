import React, { createContext, useCallback, useState, useContext } from 'react';
import Cookies from 'js-cookie';
import { encode, decode } from 'utils/crypto';
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import { User } from 'interfaces/User';
import { AuthContextData } from 'interfaces/AuthContextData';
import { AuthState } from 'interfaces/AuthState';
import { VerifyTokenData } from 'interfaces/VerifyTokenData';
import { getCookie } from 'utils/getCookie';
import api from '../services/api';
import ApiConfig from '../config/api';
import { updateOneSignalUser } from './onesignal';

const domainName = (() => {
  const domain = window.location.hostname.split('.').slice(-2).join('.');
  return domain;
})();

export const removeAuthCookies = () => {
  const getAllCookies = Cookies.get();

  for (let cookie in getAllCookies) {
    Cookies.remove(cookie, { domain: domainName });
  }

  window.localStorage.clear();
};

const isProduction = JSON.parse(process.env.REACT_APP_PRODUCTION!);
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const history = useHistory();

  const [data, setData] = useState<AuthState>(() => {
    const encodedStoredToken = window.localStorage.getItem('authToken');
    const encodedStoredExpiresIn = window.localStorage.getItem('expiresIn');
    const encodedStoredUser = window.localStorage.getItem('user');

    try {
      const accessToken = encodedStoredToken && decode(encodedStoredToken);
      const expiresIn =
        encodedStoredExpiresIn && decode(encodedStoredExpiresIn);
      const user = encodedStoredUser && decode(encodedStoredUser);

      if (accessToken && expiresIn && user) {
        return { accessToken, expiresIn, user: JSON.parse(user) } as AuthState;
      }
    } catch (error) {
      removeAuthCookies();

      return {} as AuthState;
    }

    return {} as AuthState;
  });

  const signOut = useCallback(() => {
    removeAuthCookies();

    setData({} as AuthState);

    history.push('/');
  }, [history]);

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post(
      `${process.env.REACT_APP_AUTHENTICATE}/authenticate`,
      {
        email,
        password,
      },
    );

    const userConfig: any = jwtDecode(response.data.access_token);
    const { user } = userConfig;

    const oneSignalId = getCookie('onesignal_push_id');

    if (oneSignalId && isProduction) {
      await updateOneSignalUser(oneSignalId, user.id);
    }

    const { access_token, expires_in } = response.data;

    let expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 320);

    window.localStorage.setItem('authToken', String(encode(access_token)));
    Cookies.set('authToken', encode(access_token), {
      domain: domainName,
      secure: true,
      expires: expirationDate,
    });

    window.localStorage.setItem(
      'expiresIn',
      String(encode(expires_in.toString())),
    );
    Cookies.set('expiresIn', encode(expires_in.toString()), {
      domain: domainName,
      secure: true,
      expires: expirationDate,
    });

    window.localStorage.setItem('user', String(encode(JSON.stringify(user))));
    window.localStorage.setItem(
      'default_language',
      String(user.config.default_language),
    );

    Cookies.set('user', encode(JSON.stringify(user)), {
      domain: domainName,
      secure: true,
      expires: expirationDate,
    });

    Cookies.set('default_language', user.config.default_language, {
      domain: domainName,
      secure: true,
      expires: expirationDate,
    });

    let firstAccess = response.data.first_access;
    if (firstAccess) {
      Cookies.set('first_access', firstAccess, {
        domain: domainName,

        secure: true,
        expires: expirationDate,
      });
    }

    setData({
      user,
      expiresIn: expires_in,
      accessToken: access_token,
    });
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      Cookies.set('user', encode(JSON.stringify(user)));

      setData(prevState => ({
        ...prevState,
        user,
      }));
    },
    [setData],
  );

  const doRefreshToken = useCallback(async () => {
    try {
      const currentRefreshToken = Cookies.get('refreshToken');

      if (!currentRefreshToken) return null;

      const { data: response } = await api.post(
        `${ApiConfig.urlV1}/refresh-token`,
        {
          refresh_token: decode(currentRefreshToken),
        },
      );

      Cookies.set('authToken', encode(response.token), {
        domain: domainName,
      });

      Cookies.set('refreshToken', encode(response.refreshToken), {
        domain: domainName,
      });

      return response.token;
    } catch (error) {
      signOut();
    }

    return null;
  }, [signOut]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        updateUser,
        refreshToken: doRefreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

async function validateToken(verifyTokenData: VerifyTokenData): Promise<any> {
  try {
    const response = await api.post(`${ApiConfig.urlV1}/check-token`, {
      email: verifyTokenData.email,
      token: verifyTokenData.token,
    });

    if (response.status === 200 && !response.data.errors) {
      return response;
    }
  } catch (error) {
    return {};
  }
}

function getSignedUser(): User | false {
  try {
    const encodedUser = Cookies.get('user');
    const user = encodedUser && decode(encodedUser);

    if (user) {
      return JSON.parse(user);
    }

    return false;
  } catch (error) {
    return false;
  }
}

export { AuthContext, AuthProvider, useAuth, validateToken, getSignedUser };
