import styled from 'styled-components';

export const AnimatedErrorIconContainer = styled('div').attrs(
  {} as { size: number },
)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 8px 0;
  text-align: censter;

  .error-checkmark {
    position: relative;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    animation-name: bump;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    transform: scale(1);

    .circle {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border: ${props => `${props.size * 0.04}px`} solid #f61313;
      border-radius: 50%;
    }

    .line {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      height: ${props => `${props.size * 0.04}px`};
      border-radius: 100px;
      background-color: #f61313;
    }

    .line-box {
      width: 100%;
      height: 100%;
      transform: translate(-46%, -4%);
    }

    .line.left {
      transform: rotate(45deg);
    }

    .line.right {
      transform: rotate(-45deg);
    }
  }

  @keyframes bump {
    from {
      transform: scale(1.2);
    }
    50% {
      transform: scale(0.8);
    }
    to {
      transform: scale(1);
    }
  }
`;
