import React from 'react';
import { AnimatedErrorIconContainer } from './styles';

interface AnimatedErrorIconProps {
  size?: number;
}

const AnimatedErrorIcon: React.FC<AnimatedErrorIconProps> = ({ size }) => {
  return (
    <AnimatedErrorIconContainer size={size || 24}>
      <div className="error-checkmark">
        <div className="circle">
          <div className="line-box">
            <span className="line left"></span>
            <span className="line right"></span>
          </div>
        </div>
      </div>
    </AnimatedErrorIconContainer>
  );
};

export default AnimatedErrorIcon;
